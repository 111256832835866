exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-review-freshman-js": () => import("./../../../src/pages/review/freshman.js" /* webpackChunkName: "component---src-pages-review-freshman-js" */),
  "component---src-pages-review-junior-js": () => import("./../../../src/pages/review/junior.js" /* webpackChunkName: "component---src-pages-review-junior-js" */),
  "component---src-pages-review-sophomore-js": () => import("./../../../src/pages/review/sophomore.js" /* webpackChunkName: "component---src-pages-review-sophomore-js" */),
  "component---src-pages-showcase-experiences-index-tsx": () => import("./../../../src/pages/showcase/experiences/index.tsx" /* webpackChunkName: "component---src-pages-showcase-experiences-index-tsx" */),
  "component---src-pages-showcase-projects-index-tsx": () => import("./../../../src/pages/showcase/projects/index.tsx" /* webpackChunkName: "component---src-pages-showcase-projects-index-tsx" */),
  "component---src-templates-article-page-tsx": () => import("./../../../src/templates/article-page.tsx" /* webpackChunkName: "component---src-templates-article-page-tsx" */),
  "component---src-templates-experience-page-tsx": () => import("./../../../src/templates/experience-page.tsx" /* webpackChunkName: "component---src-templates-experience-page-tsx" */),
  "component---src-templates-project-page-tsx": () => import("./../../../src/templates/project-page.tsx" /* webpackChunkName: "component---src-templates-project-page-tsx" */)
}

